import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import ParentProfile from "@components/parent_profile"
import AccountBalance from "@components/_molecules/account_balance"
import FinishSetup from "@components/_molecules/finish_setup"
import AutoDeposit from "@components/_molecules/auto_deposit_status"
import { transactions } from "@data/sample_data/transactions.js"
import TransactionList from "@components/_molecules/list_transactions"
import AccountTransactions from "@components/account_transactions"

const AccountBalancePage = () => {
  const { fundingAccount } = data
  return (
    <Layout type="app">
      <SEO title={fundingAccount.title} />
      <AccountTransactions />
    </Layout>
  )
}

export default AccountBalancePage
