import React, { Component } from "react"
import "./style.scss"
import AccountBalance from "@components/_molecules/account_balance"
import FinishSetup from "@components/_molecules/finish_setup"
import AutoDeposit from "@components/_molecules/auto_deposit_status"
import { transactions } from "@data/sample_data/transactions.js"
import TransactionList from "@components/_molecules/list_transactions"
import ProfileMenu from "@components/_molecules/profile_menu"
import AppGreeting from "@components/_molecules/app_greeting"
import BackPage from "@components/_molecules/back_parent_dashboard"
import * as data from "@data/pledge"

class AccountTransactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      custodianCashBalance: null,
      beneficiaryCashBalance: null,
      beneficiaryEquityValue: null
    }
    this.handleStateChange = this.handleStateChange.bind(this)
  }

  handleStateChange = state => {
    this.setState(state)
  }

  render() {
    const { fundingAccount } = data
    return (
      <div className="listed-page">
        <div className="heading-size">
          <BackPage data={fundingAccount.heading} />
          <AppGreeting data={fundingAccount.heading} theme="green" />
        </div>
        <div className="parent-profile">
          <div className="rows">
            <div className="spacing">
              <AccountBalance handleStateChange={this.handleStateChange} />
            </div>
            <div className="sizing">
              <TransactionList
                data={{
                  custodianCashBalance: this.state.custodianCashBalance,
                  beneficiaryEquityValue: this.state.beneficiaryEquityValue,
                  beneficiaryCashBalance: this.state.beneficiaryCashBalance
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AccountTransactions
