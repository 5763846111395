import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "./react-tabs.scss"
import { Link } from "gatsby"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"

class TransactionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
      childTransactions: []
    }
  }
  componentDidMount = () => {
    let _this = this
    let fundingtransactionsQuery = {
      query: `query {
        transactions {
          nodes {
            id
            customerId
            description
            amount
            referenceNumber
            settlementStatus
            source
            transactionNumber
            transactionStatus
            type
            createdAt
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }`
    }
    let childTransactionsQuery = {
      query: `query {
        user {
          beneficiaries {
            userCourses(isCompleted: true) {
              nodes {
                course {
                  title
                }
                rewardAmount
                rewardType
                completedAt
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              totalCount
            }
          }
        }
      }`
    }

    const options = {
      method: "POST",
      data: JSON.stringify(fundingtransactionsQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    const childOptions = {
      method: "POST",
      data: JSON.stringify(childTransactionsQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({
            transactions: res.data.data.transactions.nodes
          })
        }
      })
      .catch(error => {
        console.log(error)
      })

    axios(childOptions)
      .then(res => {
        console.log(res)
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({
            childTransactions:
              res.data.data.user.beneficiaries[0].userCourses.nodes
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  errorHandler = err => {
    console.log(err)
  }
  formatDate = date => {
    var options = {
      year: "numeric",
      month: "short",
      day: "numeric"
    }

    return new Date(date).toLocaleDateString("en-US", options)
  }
  childAccountBalance = (beneficiaryCashBalance, beneficiaryEquityValue) => {
    if (beneficiaryCashBalance === null && beneficiaryEquityValue === null) {
      return 0
    } else if (
      beneficiaryEquityValue !== null &&
      beneficiaryCashBalance === null
    ) {
      return beneficiaryEquityValue
    } else if (
      beneficiaryCashBalance !== null &&
      beneficiaryEquityValue === null
    ) {
      return beneficiaryCashBalance
    } else {
      return beneficiaryCashBalance + beneficiaryEquityValue
    }
  }
  render() {
    const { data } = this.props
    const list_transactions = (trans, index) => (
      <div className="transaction-line" key={index}>
        <div className="entry">
          <img alt="Bank" src="/images/pledge/bank.svg" />
          <div className="institution">
            <p>{trans.description}</p>
            <p className="date">{this.formatDate(trans.createdAt)}</p>
          </div>
        </div>
        <div>
          <p>
            {trans.type === "withdrawal"
              ? "-" +
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                }).format(trans.amount)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                }).format(trans.amount)}
          </p>
        </div>
      </div>
    )
    const courseLine = (value, index) => (
      <div className="course-line" key={index}>
        <img alt="Bank" src="/images/pledge/bank.svg" />
        <div className="entry">
          <div className="institution">
            <p>{value.course.title}</p>
            <p className="date">{this.formatDate(value.completedAt)}</p>
          </div>
          <div>
            <p>
            {value.rewardType === "usd"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                  }).format(value.rewardAmount)
                : "🥑 " + parseInt(value.rewardAmount)}
            </p>
          </div>
        </div>
      </div>
    )

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    })

    return (
      <div className="transaction-list container-app">
        <Tabs>
          <TabList>
            <Tab>Funding Account</Tab>
            <Tab>Child Account</Tab>
          </TabList>
          <TabPanel>
            <p className="balance">
              {currencyFormatter.format(data.custodianCashBalance)}
            </p>
            <p className="title">History</p>
            {this.state.transactions.map((trans, index) =>
              list_transactions(trans, index)
            )}
          </TabPanel>
          <TabPanel>
            <p className="balance">
              {currencyFormatter.format(
                this.childAccountBalance(
                  data.beneficiaryCashBalance,
                  data.beneficiaryEquityValue
                )
              )}
            </p>
            <p className="title">History</p>
            {this.state.childTransactions.map((value, index) =>
              courseLine(value, index)
            )}
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}
export default TransactionList
