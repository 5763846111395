import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import { Link } from "gatsby"
import * as errors from "@services/errors"
import Loading from "@components/_molecules/loading_state"

class AccountBalance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      investmentAccountId: null,
      totalAccountBalance: null,
      custodianCashBalance: null,
      beneficiaryCashBalance: null,
      beneficiaryEquityValue: null,
      recurringDepositAmount: null,
      registrationCompletedAt: null,
      bankAccount: null,
      hasBeneficiaries: false,
      loading: true
    }
  }

  componentDidMount = () => {
    let _this = this,
      { beneficiaryId } = localStorage,
      accountBalanceQuery = {
        query: `query {
          user {
            registrationCompletedAt
            beneficiaries {
              id
              investmentAccounts {
                id
                summary {
                  equity {
                    equityValue
                  }
                }
              }
              balance {
                cashTotal
                equitiesTotal
              }
            }
            paymentAccounts(linked: true) {
              id
              name
              displayAccountNumber
              status
            }
            balance { total }
            recurringDeposits { nodes { amount } }
          }
        }
        `
      }
    const options = {
      method: "POST",
      data: JSON.stringify(accountBalanceQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }

    let investmentAccountId,
      totalAccountBalance,
      custodianCashBalance,
      beneficiaryCashBalance,
      beneficiaryEquityValue,
      recurringDepositAmount,
      registrationCompletedAt,
      bankAccount,
      hasBeneficiaries

    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          const user = res.data.data.user
          custodianCashBalance = parseFloat(user.balance.total)
          totalAccountBalance = custodianCashBalance
          registrationCompletedAt = user.registrationCompletedAt

          hasBeneficiaries = user.beneficiaries.length > 0
          if (hasBeneficiaries) {
            const beneficiary = user.beneficiaries[0] // todo - handle multiple beneficiaris...
            beneficiaryCashBalance = parseFloat(beneficiary.balance.cashTotal)
            beneficiaryEquityValue = parseFloat(beneficiary.balance.equitiesTotal)

            totalAccountBalance += beneficiaryCashBalance + beneficiaryEquityValue
          }

          if (user.recurringDeposits.nodes.length) {
            recurringDepositAmount = user.recurringDeposits.nodes[0].amount
          }
          if (user.paymentAccounts.length) {
            bankAccount = user.paymentAccounts[0].status
          }

          Object.assign(
            _this.state,
            {
              investmentAccountId: investmentAccountId,
              totalAccountBalance: totalAccountBalance,
              custodianCashBalance: custodianCashBalance,
              beneficiaryCashBalance: beneficiaryCashBalance,
              beneficiaryEquityValue: beneficiaryEquityValue,
              recurringDepositAmount: recurringDepositAmount,
              registrationCompletedAt: registrationCompletedAt,
              bankAccount: bankAccount,
              hasBeneficiaries: hasBeneficiaries,
              loading: false
            }
          )
 
          if (_this.props.handleStateChange != null) {
            _this.props.handleStateChange(_this.state)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  errorHandler = err => {
    this.setState({
      generalError: errors.messaging(err)
    })
  }

  render() {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    })
    return (
      <div className="container-app acct-balance-tile">
        <p className="title">TOTAL ACCOUNT BALANCES</p>
        <Loading loading={this.state.loading} className="account-balance">
          <Link className="balance" to="/app/account-balances">
            {currencyFormatter.format(this.state.totalAccountBalance)}
          </Link>
          <GeneralSubmitMessage error={this.state.generalError} />
        </Loading>
      </div>
    )
  }
}
export default AccountBalance
